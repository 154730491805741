export const INTRODUCE_LINKAREER =
  '//medium.com/specupad/introduce-linkareer-c3e285beb0'
export const SPECUPAD_JOBPOSTS = '//specupad.com/careers/#jobPosts'
export const AD_CONTACT =
  '//docs.google.com/forms/d/e/1FAIpQLSeshiJ7LduGo4VxUQ3gQnz-_PyUoRNc7CC1BClRtu17VChmjw/viewform'
export const TIMESPREAD_LECTURES = '//timespread.co.kr/lectures'
export const ACTIVITY_BOT = '//pf.kakao.com/_jXHxoT'
export const CONTEST_BOT = '//pf.kakao.com/_JnqAxb'
export const RECRUIT_BOT = '//pf.kakao.com/_IxeGdxb'
export const COVERLETTER_BOT = '//pf.kakao.com/_MGgexb'
export const LINKAREER_BLOG = '//blog.naver.com/linkareer1004'
export const LINKAREER_POST = '//post.naver.com/linkareer1004?isHome=1'
export const FB_UNIVWHAT = '//www.facebook.com/univwhat'
export const FB_CONTESTWHAT = '//www.facebook.com/contestWhat'
export const SPECUP_CAFE = '//cafe.naver.com/specup'
export const MEDIA_INTRODUCTION =
  'https://s3.ap-northeast-2.amazonaws.com/media.linkareer.com/business/linkareer_2005.pdf'
export const FINANCIAL_REPORTS =
  'https://timespread.notion.site/100ab4d5e55f4d89958fdb50653e5840'
